<template>
  <div class="openCopy">
    <div class="serach_box">
      <a-dropdown class="routeBtn ant-btn-primary">
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item key="dept_count">部门数据</a-menu-item>
          <a-menu-item key="count">个人统计</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px">
          数据统计 <a-icon type="down" />
        </a-button>
      </a-dropdown>
      <a-form layout="inline" style="padding-right: 100px;">
        <a-form-item>
          <a-input
            placeholder="请输入文档编号"
            style="width:200px;"
            v-model="DocNo"
          />
        </a-form-item>
        <a-form-item>
          <a-input
            placeholder="请输入文档名称"
            style="width:200px;"
            v-model="Name"
          />
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 160px"
            v-model="DocumentStatus"
            placeholder="请选择文档状态"
          >
            <a-select-option
              v-for="(item, k) in world_type_arr"
              :key="k"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 120px"
            v-model="JobType"
            placeholder="作业类型"
          >
            <a-select-option
              v-for="(item, k) in job_type_arr"
              :key="k"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <!-- <a-form-item>
                    <a-input placeholder="请输入组织机构" style="width:200px;" v-model="DepartmentName"/>
                </a-form-item> -->
        <a-form-item>
          <a-tree-select
            style="min-width: 220px;"
            :treeData="department_arr"
            :value="DepartmentName ? DepartmentName : undefined"
            @change="onChangeTree"
            treeNodeLabelProp="title"
            :showCheckedStrategy="SHOW_PARENT"
            placeholder="请选择部门"
          />
        </a-form-item>
        <a-form-item>
          <a-input
            placeholder="请输入账号"
            style="width:200px;"
            v-model="UserAccount"
          />
        </a-form-item>
        <a-form-item>
          <a-input
            placeholder="请输入姓名"
            style="width:200px;"
            v-model="UserName"
          />
        </a-form-item>
        <a-form-item>
          <a-input
            placeholder="请输入打印机名称"
            style="width:200px;"
            v-model="PrinterName"
          />
        </a-form-item>
        <a-form-item>
          <a-range-picker
            style="width:220px;"
            :value="createValue"
            format="YYYY-MM-DD"
            :placeholder="['开始时间', '结束时间']"
            allowClear
            @change="changeTime"
          />
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 120px"
            v-model="ColorType"
            placeholder="请选择颜色"
          >
            <a-select-option
              v-for="(item, k) in color_type_arr"
              :key="k"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 120px"
            v-model="SimpleDuplexType"
            placeholder="请选择单双面"
          >
            <a-select-option
              v-for="(item, k) in pressing_type_arr"
              :key="k"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 140px"
            v-model="OutputTime"
            placeholder="请筛选输出时间"
          >
            <a-select-option value="0">所有输出时间</a-select-option>
            <a-select-option value="1">输出时间为空</a-select-option>
            <a-select-option value="2">输出时间非空</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button style="margin-right:20px" @click="resetBtn()"
            >重置</a-button
          >
          <a-button
            style="margin-right:20px"
            type="primary"
            @click="serachBtn()"
            >查询</a-button
          >
          <a-button type="primary" @click="upLoadFn()">同步数据</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-table
      :columns="columns"
      :dataSource="dataList"
      :pagination="false"
      size="small"
      :customRow="rowClick"
      :scroll="{ x: '100%' }"
      style="margin-top:10px;border:1px solid #efefef"
    >
      <template slot="action" slot-scope="columnsInfo">
        <a-dropdown :trigger="['hover']">
          <a style="display:block;color:#000;"> <a-icon type="file-text"/></a>
          <a-menu slot="overlay">
            <a-menu-item key="0" disabled>
              <table
                style="width:260px;text-align:center;line-height:30px;border:1px solid #ddd;color:#444444;"
                cellpadding="0"
                cellspacing="0"
                border="1"
              >
                <tr>
                  <td>颜色</td>
                  <td>单双面</td>
                  <td>规格</td>
                  <td>总页数</td>
                </tr>
                <tr v-if="columnsInfo.length == 0">
                  <td colspan="5">
                    暂无数据
                  </td>
                </tr>
                <tr v-else v-for="(list, i) in columnsInfo" :key="i">
                  <td>{{ list.colorType == 1 ? '黑白' : '彩色' }}</td>
                  <td>{{ list.simpleDuplexType == 1 ? '单面' : '双面' }}</td>
                  <td>{{ list.pageSize }}</td>
                  <td>{{ list.pageCount }}</td>
                </tr>
              </table>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
      <template slot="footer" slot-scope="currentPageData">
        <div style="font-weight:bold;">
          <span style="margin-right:20px;"> 合计:</span> {{ total }}
        </div>
      </template>
    </a-table>
    <div style="display:flex;margin: 20px 0;justify-content: space-between;">
      <div>
        每页<a-input
          type="number"
          placeholder="12"
          ref="pages"
          :step="5"
          :min="10"
          :max="9999"
          size="small"
          style="width:68px;"
        />条
        <a-button type="primary" size="small" @click="changeSize"
          >确定</a-button
        >
      </div>
      <a-pagination
        showQuickJumper
        :defaultCurrent="1"
        :total="total"
        :pageSize="Number(pagesize)"
        @change="pageChange"
      />
    </div>

    <a-spin style="position:fixed;left:50%;top:150px" v-if="spinning">
      <a-icon
        slot="indicator"
        type="loading"
        :spinning="spinning"
        style="font-size: 44px;color:red;"
        spin
      />
    </a-spin>
  </div>
</template>
<script>
const columns = [
  { title: '文档编号', key: 'DocNo', width: 180, dataIndex: 'DocNo' },
  { title: '文档名称', key: 'Name', width: 220, dataIndex: 'Name' },
  { title: '类型', key: 'JobType', dataIndex: 'JobType', width: 80 },
  {
    title: '状态',
    key: 'DocumentStatus ',
    dataIndex: 'DocumentStatus',
    width: 120
  },
  {
    title: '部门',
    key: 'DepartmentName',
    width: 100,
    dataIndex: 'DepartmentName'
  },
  { title: '账号', key: 'UserAccount', width: 140, dataIndex: 'UserAccount' },
  { title: '姓名', key: 'UserName', width: 80, dataIndex: 'UserName' },
  {
    title: '打印机名称',
    key: 'PrinterName',
    width: 100,
    dataIndex: 'PrinterName'
  },
  { title: '提交时间', key: 'CreateTime', width: 160, dataIndex: 'CreateTime' },
  { title: '输出时间', key: 'OutputTime', width: 160, dataIndex: 'OutputTime' },
  { title: '颜色', key: 'ColorType', width: 54, dataIndex: 'ColorType' },
  { title: '单双面', key: 'Status', width: 64, dataIndex: 'SimpleDuplexType' },
  { title: '规格', key: 'PageSize', width: 54, dataIndex: 'PageSize' },
  { title: '份数', key: 'Copies ', width: 54, dataIndex: 'Copies' },
  {
    title: '总页数',
    key: 'OutputTotalCount',
    width: 70,
    dataIndex: 'OutputTotalCount'
  },
  {
    title: '详情',
    key: 'Outputs',
    width: 60,
    dataIndex: 'Outputs',
    scopedSlots: { customRender: 'action' }
  }
]

import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import moment from 'moment'

export default {
  name: 'JobPool',
  data () {
    return {
      SHOW_PARENT,
      spinning: false,
      clientH: '400px',
      //table
      dataList: [],
      columns,
      //搜索
      posts_type_arr: [], //岗位
      posts_type: [],

      world_type_arr: [
        { label: '完成', value: '完成' },
        { label: '未完成', value: '未完成' }
      ],
      job_type_arr: [
        { label: '打印', value: '打印' },
        { label: '复印', value: '复印' },
        { label: '扫描', value: '扫描' },
        { label: '传真', value: '传真' }
      ], //紧急程度
      pressing_type_arr: [
        { label: '单面', value: '单面' },
        { label: '双面', value: '双面' }
      ],
      color_type_arr: [
        { label: '黑白', value: '黑白' },
        { label: '彩色', value: '彩色' }
      ], //颜色类型

      DocNo: '',
      Name: '',
      DepartmentName: '',
      UserAccount: '',
      UserName: '',
      PrinterName: '',
      DocumentStatus: undefined,
      JobType: undefined,
      ColorType: undefined,
      SimpleDuplexType: undefined,
      startDate: '',
      endDate: '',
      createValue: [],

      OutputTime: undefined,
      pagesize: 12, //每页10条
      index: 1, //页码
      total: 1, //总数

      department_arr: []
    }
  },
  mounted () {
    if (sessionStorage.getItem('department_arr')) {
      this.department_arr = JSON.parse(sessionStorage.getItem('department_arr'))
    } else {
      this.$axios.get(8012, {}, res => {
        //改变权限设置方发
        if (!res.data) {
          return false
        }
        if (res.data.code == 1) {
          this.department_arr = res.data.data
          sessionStorage.setItem(
            'department_arr',
            JSON.stringify(res.data.data)
          )
        }
      })
    }
    let domHeight = document.documentElement.clientHeight
    this.clientH = domHeight - 156 + 'px'
    this.tableFn()
  },
  methods: {
    onChangeTree (value) {
      console.log(value)
      this.DepartmentName = value
    },
    changeSize () {
      console.log(this.$refs.pages.stateValue)
      if (
        this.$refs.pages.stateValue < 10 ||
        this.pagesize == this.$refs.pages.stateValue
      ) {
        this.pagesize = 10
      } else {
        this.pagesize = this.$refs.pages.stateValue
      }
      this.tableFn()
    },
    handleMenuClick (val) {
      this.$router.push({ path: 'countNum', query: { type: val.key } })
    },
    upLoadFn () {
      let that = this
      that.spinning = true
      that.$axios.get(8023, {}, res => {
        console.log(res.data)
        that.spinning = false
        if (res.data.code == 1) {
          //  that.dataList = res.data.data;
          //  that.total = res.data.count;
          that.$message.success(res.data.msg)
          that.tableFn()
        } else {
          that.$message.error(res.data.msg)
        }
      })
    },
    tableFn () {
      let that = this
      let obj = {
        DocNo: that.DocNo,
        Name: that.name,
        DepartmentId: that.DepartmentName,
        UserAccount: that.UserAccount,
        UserName: that.UserName,
        PrinterName: that.PrinterName,
        DocumentStatus: that.DocumentStatus,
        JobType: that.JobType,
        ColorType: that.ColorType,
        SimpleDuplexType: that.SimpleDuplexType,
        PageSize: that.pagesize,
        Page: that.index,
        StartTime: that.startDate,
        EndTime: that.endDate,
        OutputTime: that.OutputTime
      }
      that.spinning = true
      that.$axios.get(8022, obj, res => {
        // console.log(res.data);
        that.spinning = false
        if (res.data.code == 1) {
          that.dataList = res.data.data
          that.total = res.data.count
        } else {
          that.$message.error(res.data.msg)
        }
      })
    },
    // change(val){
    //     console.log(val,)
    // },
    //搜索时间
    changeTime (c, value) {
      console.log(c, value)
      this.createValue = c
      this.startDate = value[0]
      this.endDate = value[1]
      let date1 = new Date(this.startDate)
      this.startDate =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1) +
        '-' +
        (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
      let date2 = new Date(this.endDate)
      this.endDate =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1) +
        '-' +
        (date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate())
      // console.log(this.startDate,this.endDate)
    },
    //分页
    pageChange (pageNumber) {
      console.log(pageNumber)
      this.index = pageNumber
      this.tableFn()
    },
    //重置
    resetBtn () {
      this.OutputTime = undefined
      this.DocNo = ''
      this.Name = ''
      this.DepartmentName = ''
      this.UserAccount = ''
      this.UserName = ''
      this.PrinterName = ''
      this.DocumentStatus = undefined
      this.JobType = undefined
      this.ColorType = undefined
      this.SimpleDuplexType = undefined
      this.startDate = ''
      this.endDate = ''
      this.index = 1
      this.createValue = []
      // this.tableFn(1,this.page,this.classify_type,this.classify_name,this.startDate,this.endDate);
      this.tableFn()
    },
    //搜索按钮
    serachBtn () {
      let that = this
      if (
        that.OutputTime ||
        that.DocNo ||
        that.Name ||
        that.DepartmentName ||
        that.UserAccount ||
        that.UserName ||
        that.PrinterName ||
        that.DocumentStatus ||
        that.JobType ||
        that.ColorType ||
        that.SimpleDuplexType ||
        that.startDate ||
        that.endDate
      ) {
        that.index = 1
        that.tableFn()
      } else {
        that.$message.error('都为空不请求!')
        return false
      }
    },
    // 点击table
    rowClick: (record, index) => {
      return {
        on: {
          click: () => {
            console.log(record, index)
          }
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.openCopy {
  padding: 16px;
  background: #fff;

  .serach_box {
    position: relative;
    .map-position {
      position: absolute;
      top: 4px;
      right: 0px;
    }
    .item-label {
      margin-right: 12px;
      margin-bottom: 12px;
      width: 160px;
    }
  }
  .routeBtn {
    position: absolute;
    right: 0px;
    top: 5px;
  }
}
</style>
